import { useState, useEffect } from "react";
import { collection } from "firebase/firestore";
import { useFirestoreCollectionData, useFirestore } from "reactfire";
import { Button, Form, Table } from "react-bootstrap";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";

import { useFirebaseApp } from "reactfire";

import { RSVP } from "../models/rsvp";

export function AdminPage() {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState<string>();

  const signIn = () =>
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setErrorMsg(undefined);
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg("Invalid password/email combination.");
      });

  const [user, setUser] = useState<User | null>(auth.currentUser);
  // Whether we have gotten the initial auth state from firebase yet.
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setInitialized(true);
    });
  }, [auth]);

  if (!initialized) return null;

  // Yes Ryan I know you can very easily bypass this and view the page. Happy for you.
  if (!user) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
          }}
        >
          <div>
            Admin access is required to view this page, please sign in below.
          </div>
          <Form.Control
            style={{ maxWidth: 300 }}
            value={email}
            onChange={(e: any) => {
              setEmail(e.currentTarget.value);
            }}
            placeholder="Email"
          />
          <Form.Control
            style={{ maxWidth: 300 }}
            type="password"
            value={password}
            onChange={(e: any) => {
              setPassword(e.currentTarget.value);
            }}
            placeholder="Password"
          />
          <Button onClick={() => signIn()}>Sign in</Button>
          {errorMsg && <div style={{ color: "red" }}>{errorMsg}</div>}
        </div>
      </div>
    );
  }

  return (
    <>
      <Button onClick={() => auth.signOut()}>Sign out</Button>
      <RsvpTable />
    </>
  );
}

function RsvpTable() {
  const dataRef = collection(useFirestore(), "rsvp");
  const { status, data } = useFirestoreCollectionData(dataRef);

  if (status === "loading") {
    return null;
  }

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <b>RSVPs</b>
      </div>
      <div style={{ textAlign: "left" }}>
        Guest count total:
        <b> {totalGuestCount(data as RSVP[])}</b>
        <br />
        Welcome Party count:
        <b> {totalWelcomeCount(data as RSVP[])}</b>
        <br />
        Not Coming Total:
        <b> {totalNoGuestCount(data as RSVP[])}</b>
        <br />
        RSVP Outstanding Total:
        <b> {totalOutstandingCount(data as RSVP[])}</b>
        <br />
      </div>
      <Table striped bordered hover style={{ width: "auto" }}>
        <thead>
          <tr>
            {[
              "Id",
              "Invites",
              "Guest",
              "Count",
              "Viewed",
              "Submitted",
              "Welcome",
            ].map((h) => (
              <th style={{ textAlign: "left" }}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d) => {
            const id = d["NO_ID_FIELD"];
            const r = d as RSVP;
            return (
              <tr key={id}>
                <td style={{ textAlign: "left" }}>{id}</td>
                <td style={{ textAlign: "left" }}>
                  {r.invites
                    .map((r) => `${r.name}${statusString(r.status)}`)
                    .sort()
                    .join(", ")}
                </td>
                <td style={{ textAlign: "left" }}>{guestString(r)}</td>
                <td>{guestCount(r)}</td>
                <td>{r.hasViewed ? "Y" : ""}</td>
                <td>{r.hasSubmitted ? "Y" : ""}</td>
                <td>{statusString(r.welcome)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <p></p>
      <p></p>
      <h3 style={{ textAlign: "left" }}>Extra info</h3>
      <Table striped bordered hover style={{ width: "auto" }}>
        <thead>
          <tr>
            {["Invites", "Diet", "Music", "Note"].map((h) => (
              <th style={{ textAlign: "left" }}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d) => {
            const id = d["NO_ID_FIELD"];
            const r = d as RSVP;
            return (
              <tr key={id}>
                <td style={{ textAlign: "left" }}>
                  {r.invites
                    .map((r) => `${r.name}${statusString(r.status)}`)
                    .sort()
                    .join(", ")}
                </td>
                <td style={{ textAlign: "left" }}>{r.diet}</td>
                <td style={{ textAlign: "left" }}>{r.music}</td>
                <td style={{ textAlign: "left" }}>{r.note}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

function guestString(r: RSVP) {
  if (!r.allowedGuest) {
    return "";
  } else if (r.allowedGuest && r.guest.status === -1) {
    return "Not bringing";
  } else if (r.allowedGuest && r.guest.status === 0) {
    return "No RSVP";
  } else {
    return "Bringing " + r.guest.name;
  }
}

function statusString(status: number) {
  if (status === 1) {
    return " [Yes]";
  } else if (status === -1) {
    return " [No]";
  }
  return "";
}

function guestCount(r: RSVP) {
  let count = 0;
  for (let i = 0; i < r.invites.length; i++) {
    count = count + (r.invites[i].status === 1 ? 1 : 0);
  }
  if (r.allowedGuest) {
    count = count + (r.guest.status === 1 ? 1 : 0);
  }
  return count;
}

function totalGuestCount(r: RSVP[]) {
  const totalGuestsComing = r.reduce((total, rsvp) => {
    const guestCount = rsvp.allowedGuest && rsvp.guest.status === 1 ? 1 : 0;
    return (
      total +
      rsvp.invites.reduce((totalInvites, invite) => {
        return totalInvites + (invite.status === 1 ? 1 : 0);
      }, 0) +
      guestCount
    );
  }, 0);
  return totalGuestsComing;
}

function totalWelcomeCount(r: RSVP[]) {
  const totalWelcomeComing = r.reduce((total, rsvp) => {
    const guestCount = rsvp.allowedGuest && rsvp.guest.status === 1 ? 1 : 0;
    const welcomeComing = rsvp.welcome === 1 ? 1 : 0;
    return (
      total +
      (rsvp.invites.reduce((totalInvites, invite) => {
        return totalInvites + (invite.status === 1 ? 1 : 0);
      }, 0) +
        guestCount) *
        welcomeComing
    );
  }, 0);
  return totalWelcomeComing;
}

function totalNoGuestCount(r: RSVP[]) {
  const totalNotComing = r.reduce((total, rsvp) => {
    const guestCount = rsvp.allowedGuest && rsvp.guest.status === -1 ? 1 : 0;
    return (
      total +
      rsvp.invites.reduce((totalInvites, invite) => {
        return totalInvites + (invite.status === -1 ? 1 : 0);
      }, 0) +
      guestCount
    );
  }, 0);
  return totalNotComing;
}

function totalOutstandingCount(r: RSVP[]) {
  const totalNotComing = r.reduce((total, rsvp) => {
    return (
      total +
      rsvp.invites.reduce((totalInvites, invite) => {
        return totalInvites + (invite.status === 0 ? 1 : 0);
      }, 0)
    );
  }, 0);
  return totalNotComing;
}
