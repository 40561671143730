import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";

import { AppBar, DJLogo } from "./components/AppBar";
import { AppContext } from "./AppContext";
import { useIsMobile } from "./util/UseIsMobile";
import { AdminPage } from "./pages/AdminPage";
import { HomePage } from "./pages/HomePage";
import { RegistryPage } from "./pages/RegistryPage";
import { ThingsToDoPage } from "./pages/ThingsToDoPage";
import { WeddingPage } from "./pages/WeddingPage";
import { useWindowSize } from "./util/UseWindowSize";

const config = {
  apiKey: "AIzaSyCJc1j1Pq48ngjc3qUG8K77SWT9YNOtpBQ",
  authDomain: "wedding-website-393718.firebaseapp.com",
  projectId: "wedding-website-393718",
  storageBucket: "wedding-website-393718.appspot.com",
  messagingSenderId: "445952323206",
  appId: "1:445952323206:web:bc5b45dda809f7accc6b07",
  measurementId: "G-8TLY2QE9GB",
};

const app = initializeApp(config);
getAnalytics(app);

function App() {
  return (
    <AppContext.Provider value={app}>
      <FirebaseAppProvider firebaseConfig={config}>
        <AppInner />
      </FirebaseAppProvider>
    </AppContext.Provider>
  );
}

function AppInner() {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const storageInstance = getStorage(app);
  const isMobile = useIsMobile();

  const { height } = useWindowSize();
  const headerHeight = isMobile ? 66 : 248;
  const footerHeight = 70;
  const footerPadding = isMobile ? 0 : 12;

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <StorageProvider sdk={storageInstance}>
        <header>
          <AppBar />
        </header>
        <main
          style={{
            minHeight:
              (height || 0) - headerHeight - footerHeight - footerPadding,
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: isMobile ? 64 : 8,
            }}
          >
            <Container>
              <Routes>
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/schedule" element={<WeddingPage />} />
                <Route path="/things-to-do" element={<ThingsToDoPage />} />
                <Route path="/registry" element={<RegistryPage />} />
                <Route path="*" element={<Navigate replace to={`/home`} />} />
              </Routes>
            </Container>
          </div>
        </main>
        <footer>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DJLogo />
          </div>
        </footer>
      </StorageProvider>
    </FirestoreProvider>
  );
}

export default App;
