import { Header } from "../components/Header";

import { useIsMobile } from "../util/UseIsMobile";

const MAIN_PHOTO = "/img/front-page.jpg";

export function HomePage() {
  const isMobile = useIsMobile();

  return (
    <div>
      {isMobile ? <Header /> : ""}
      <div>
        <div style={{ margin: "20px 0"}}>
          We couldn't wait to get married, so we eloped! But we still want to celebrate with you!
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 16,
        }}
      ></div>
      <img
        src={MAIN_PHOTO}
        style={{
          width: "100%",
        }}
        alt="drew and jen"
      />
    </div>
  );
}
