import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Header } from "../components/Header";

import { useIsMobile } from "../util/UseIsMobile";
import {
  ACCENT_COLOR,
  BACKGROUND_COLOR,
  SELECTED_NAV_BACKGROUND,
  TEXT_COLOR,

  HEADER_FONT,
} from "../constants";
import { HorizontalLine } from "./HorizontalLine";

export function DJLogo() {
  return (
    <span
      style={{
        fontFamily: HEADER_FONT,
        paddingTop: "5px",
        paddingRight: "5px",
        paddingLeft: "5px",
        verticalAlign: "middle",
      }}>
      <b>D&amp;J</b>
    </span>
  );
}

export function AppBar() {
  const isMobile = useIsMobile();
  const location = useLocation();
  const active = location.pathname.split("/")[1];

  const tabs = [
    "home",
    "schedule",
    "things-to-do",
    "registry",
  ];

  if (!isMobile) {
    return (
      <Container style={{ textAlign: "center" }}>
        <Header />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 32,
            marginBottom: 32,
          }}
        >
          <HorizontalLine />
          <div>
            {tabs.map((t) => (
              <Link
                key={t}
                to={`/${t}`}
                style={{
                  display: "inline",
                  color: ACCENT_COLOR,
                  padding: 8,
                  marginLeft: 8,
                  marginRight: 8,
                  backgroundColor: active === t ? SELECTED_NAV_BACKGROUND : "",
                  borderRadius: "3px",
                  textTransform: "uppercase",
                  textDecoration: "none",
                  fontWeight: 600,
                }}
              >
                {t.replaceAll("-", " ")}
              </Link>
            ))}
          </div>
          <HorizontalLine />
        </div>
      </Container>
    );
  }

  return (
    <Navbar
      expand={false}
      fixed="top"
      collapseOnSelect={true}
      style={{
        background: BACKGROUND_COLOR,
      }}
    >
      <Container>
        <Navbar.Brand
          href="/home"
          style={{
            display: "block",
          }}>
          <DJLogo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className="me-auto">
            {tabs.map((t) => (
              <Nav.Link
                key={t}
                as={Link}
                to={`/${t}`}
                href={`/${t}`}
                style={{ textTransform: "uppercase", color: TEXT_COLOR}}
              >
                {t}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
