import { Row, Col } from "react-bootstrap";

import { useIsMobile } from "../util/UseIsMobile";
import { PageHeader } from "../components/PageHeader";
import { TEXT_COLOR } from "../constants";
import { FaBus, FaCar } from "react-icons/fa";
import { ReactNode } from "react";

const FA_ICON_SIZE = 20;
const WEDDING_DATE = Date.parse("07/06/2024");

const timeToStr = (min: number) => {
  const time = WEDDING_DATE + min * 60 * 1000;
  const timeStr = new Date(time).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  return timeStr;
};

interface Section {
  header: string;
  items: ReactNode[];
  icon: JSX.Element;
}

interface Event {
  name: string,
  placeName: string;
  address: string;
  attire: string;
  runtime: string;
  mapLink: string;
  mapEmbedLink: string;
  extraInfo: JSX.Element;
  gettingThere: Section[];
}

interface ScheduleEntry {
  time: number; // Time in minutes.
  event: string;
}

const schedule: ScheduleEntry[] = [
  { time: 17 * 60 + 30, event: "Cocktail Hour" },
  { time: 18 * 60 + 30, event: "Dinner and Speeches" },
  { time: 22 * 60 + 30, event: "You Don't Have To Go Home, But You Can't Stay Here" },
];

const raysBoathouse: Event = {
  name: "Reception",
  placeName: "Ray's Boathouse",
  address: "6049 Seaview Ave NW, Seattle, WA 98107",
  attire: "Semi-formal",
  runtime: "5:30 PM - 10:30 PM",
  mapLink: "https://www.google.com/maps/place/Ray's+Boathouse/@47.673388,-122.407643,15z/data=!4m6!3m5!1s0x549015de04ed58b1:0x43c0351360cea090!8m2!3d47.673388!4d-122.407643!16s%2Fm%2F05z3hjp?entry=ttu",
  mapEmbedLink: "https://www.google.com/maps/embed/v1/place?q=Ray's+Boathouse,+Seaview+Avenue+Northwest,+Seattle,+WA,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
  extraInfo:
    <div>
      <div>
        We are so excited to invite to celebrate our wedding with us. Our
        wedding will be a fun and relaxed affair, with delicious food, great
        music, and fabulous company. We hope you can join us and share in our
        joy. Please RSVP by May 31st. Thank you for being part of our lives
        and our big day!
      </div>
      <h2 style={{ textAlign: "center", marginTop: 20, fontSize: 26 }}>Schedule</h2>
      <table style={{ margin: "0 auto", marginTop: 24, marginBottom: 24 }}>
        <tbody>
          {schedule.map((d, i) => (
            <tr>
              <td
                style={{
                  borderRight: `2px solid ${TEXT_COLOR}`,
                  textAlign: "left",
                  paddingLeft: 64,
                  paddingRight: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <b>{timeToStr(d.time)}</b>
              </td>
              <td
                style={{
                  border: "none",
                  textAlign: "left",
                  paddingLeft: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <b>{d.event}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>,
  gettingThere: [
    {
      header: "By Car",
      items: [
        "Getting to Ray's by car will likely be the easiest option. There is some parking at the venue, but it will be limited, so carpool if you can! There is additional parking in the surrounding neighborhoods.",
      ],
      icon: <FaCar style={{ width: FA_ICON_SIZE, height: FA_ICON_SIZE }} />,
    },
    {
      header: "By Transit",
      items: [
        "Unfortunately, Ray's is a bit out of the way for transit. All the closest stops to the venue are at least 0.5 miles away, so be prepared to walk if you choose this option."
      ],
      icon: <FaBus style={{ width: FA_ICON_SIZE, height: FA_ICON_SIZE }} />,
    },
  ]
};

const welcomeLunch: Event = {
  name: "Welcome Picnic",
  placeName: "Alki Beach",
  address: "2701 Alki Ave SW, Seattle, WA 98116",
  attire: "Casual",
  runtime: "12:00PM - 2:00 PM",
  mapLink: "https://www.google.com/maps/place/47%C2%B034'45.1%22N+122%C2%B024'40.9%22W/@47.579196,-122.412415,18z/data=!3m1!4b1!4m4!3m3!8m2!3d47.579196!4d-122.411354?entry=ttu",
  mapEmbedLink: "https://www.google.com/maps/embed/v1/place?q=2701+Alki+Ave+SW,+Seattle,+WA+98116,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
  extraInfo:
      <div>
        We have a shelter and several picnic tables reserved. We'll provide food
        and drinks and fun!
      </div>,
  gettingThere: [
    {
      header: "By Car",
      items: [
        "Getting to the beach via car will likely be the easiest option. Parking can be a bit of hassle on a nice day but if you're unable to get lucky with a spot, there is always parking available as you head away from the beach and into the surrounding neighborhoods.",
      ],
      icon: <FaCar style={{ width: FA_ICON_SIZE, height: FA_ICON_SIZE }} />,
    },
    {
      header: "By Transit",
      items: [
        <span>
          If you are staying near Downtown Seattle, a classic experience is to take
          the{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://kingcounty.gov/depts/transportation/water-taxi/west-seattle.aspx"
            style={{ color: TEXT_COLOR }}
          >
            water taxi
          </a>{" "}
          from Pier 50 on the waterfront out to West Seattle. The boat drops you
          off about 2 miles (3.2 km) from the beach but there is a free shuttle
          (Route 775) that will take you the rest of the way. More information can
          be found{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://kingcounty.gov/~/media/depts/transportation/water-taxi/king-county-water-taxi-brochure.ashx?la=en"
            style={{ color: TEXT_COLOR }}
          >
            here
          </a>
          .
        </span>,
      ],
      icon: <FaBus style={{ width: FA_ICON_SIZE, height: FA_ICON_SIZE }} />,
    },
  ],
};

const capitolHillGettingThere : Section[] = [
  {
    header: "By Car",
    items: [
      "There is limited parking on Capitol Hill (expecially during Friday evenings)! You may want to avoid driving yourself."
    ],
    icon: <FaCar style={{ width: FA_ICON_SIZE, height: FA_ICON_SIZE }} />,
  },
  {
    header: "By Transit",
    items: [
      "Capitol Hill is well served by transit, so this will probably be the easiest option. The venue is close to the light rail."
    ],
    icon: <FaBus style={{ width: FA_ICON_SIZE, height: FA_ICON_SIZE }} />,
  },
];

const stoupBrewing : Event = {
  name: "Happy Hour",
  placeName: "Stoup Brewing Capitol Hill",
  address: "1158 Broadway, Seattle, WA 98122",
  attire: "Casual",
  runtime: "6:00 PM - 8:00 PM (ish)",
  mapLink: "https://www.google.com/maps/place/Stoup+Brewing+-+Capitol+Hill/@47.6128036,-122.3231076,17z/data=!3m1!4b1!4m6!3m5!1s0x54906b1d9bf7658f:0x3f57a4daece51308!8m2!3d47.6128!4d-122.3205327!16s%2Fg%2F11l1m1btz6?entry=ttu",
  mapEmbedLink: "https://www.google.com/maps/embed/v1/place?q=Stoup+Brewing+-+Capitol+Hill,+Broadway,+Seattle,+WA,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
  extraInfo: <div>We will have a large table or a few smaller tables reserved for our group. The venue doesn't serve food, but you can bring food in and there are food trucks just outside.</div>,
  gettingThere: capitolHillGettingThere,
};

const karaoke : Event = {
  name: "Karaoke (21+)",
  placeName: "Rock Box",
  address: "1603 Nagle Pl, Seattle, WA 98122",
  attire: "Casual",
  runtime: "8:00 PM - 11:00 PM",
  mapLink: "https://www.google.com/maps/place/Rock+Box/@47.6153829,-122.320146,15z/data=!4m2!3m1!1s0x0:0xfc4eeab5e769deea?sa=X&ved=1t:2428&ictx=111",
  mapEmbedLink: "https://www.google.com/maps/embed/v1/place?q=Rock+Box,+Nagle+Place,+Seattle,+WA,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
  extraInfo: <div></div>,
  gettingThere: capitolHillGettingThere,
};

function RenderEvent(event: Event) {
  const isMobile = useIsMobile();

  return (
    <div>
      <h2 style={{ marginTop: 30 }}>{event.name}</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "24px auto",
          maxWidth: "800px",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <iframe
          title={event.placeName}
          style={{
            marginLeft: isMobile ? 0 : "130px",
            height: 250,
            width: 250,
            border: 0,
          }}
          frameBorder="0" src={event.mapEmbedLink}>
        </iframe>
        <div style={{
          textAlign: "left",
          marginLeft: "20px",
          }}>
          <h2>{event.placeName}</h2>
          <a
            target="_blank"
            rel="noreferrer"
            href="{event.mapLink}"
            style={{ color: TEXT_COLOR }}
          >
            <h3 style={{ fontSize: 16 }}>{event.address}</h3>
          </a>
          <h3 style={{ fontSize: 16 }}>Attire: {event.attire}</h3>
          <h3 style={{ fontSize: 16 }}>{event.runtime}</h3>
        </div>
      </div>
      <div style={{ maxWidth: 800, margin: "30px auto", textAlign: "left" }}>
        {event.extraInfo}
      </div>
      <h2 style={{ marginTop: 12, fontSize: 26 }}>Getting There</h2>
      <div
        style={{ justifyContent: "center", marginLeft: 12, marginRight: 12 }}
      >
        {event.gettingThere.map((s) => (
          <Row
            className="justify-content-md-center"
            key={s.header}
            style={{ marginTop: 8 }}
          >
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "inherit",
              }}
            >
              <span style={{ marginRight: 8 }}>
                {s.icon}
              </span>
              <h3 style={{ fontSize: "20px", lineHeight: "30px", verticalAlign: "middle" }}>{s.header}</h3>
            </Col>
            <Col sm={8} xs={12} md={8} lg={7}>
              {s.items.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 8,
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export function WeddingPage() {
  return (
    <div>
      <PageHeader text={"Schedule"} />
      <div>
        <h1 style={{ fontSize: 34 }}>THE DAY BEFORE</h1>
        <h2 style={{ marginTop: 0, marginBottom: 24, fontSize: 20 }}>
          July 5th, 2024
        </h2>
        <div style={{ maxWidth: 800, margin: "20px auto", textAlign: "left" }}>
          We feel very fortunate that so many of you are traveling from far and
          wide to help us celebrate our marriage! To give a bit of extra time for
          socializing, we're planning a few different events for the day before.
          Feel free to come to any or all of these events if you wish. (Locals
          welcome too!)
        </div>
        {RenderEvent(welcomeLunch)}
        {RenderEvent(stoupBrewing)}
        {RenderEvent(karaoke)}
      </div>
      <div style={{ marginTop: 48 }}>
        <h1 style={{ fontSize: 34 }}>THE DAY OF</h1>
        <h2 style={{ marginTop: 0, marginBottom: 24, fontSize: 20 }}>
          July 6th, 2024
        </h2>
        {RenderEvent(raysBoathouse)}
      </div>
    </div>
  );
}
