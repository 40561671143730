import { TEXT_COLOR } from "../constants";

export function HorizontalLine() {
  return (
    <div
      style={{
        backgroundColor: TEXT_COLOR,
        height: 2,
        flexGrow: 1,
      }}
    ></div>
  );
}
