import { TEXT_COLOR } from "../constants";
export const HEADER_FONT = "Marcellus";

export function PageHeader(props: { text: string }) {
  const { text } = props;
  return (
    <h1
      style={{
        color: TEXT_COLOR,
        fontFamily: HEADER_FONT,
        marginBottom: 12,
        paddingTop: 24,
        textTransform: "uppercase",
        fontWeight: 400,
      }}
    >
      {text}
    </h1>
  );
}
