import Container from "react-bootstrap/Container";
import { Countdown } from "../components/Countdown";

import {
  HEADER_FONT,
  PARAGRAPH_FONT,
} from "../constants";

export function Header() {
  return (
    <Container style={{ textAlign: "center" }}>
      <h1
        style={{
          fontSize: "2.8em",
          fontFamily: HEADER_FONT,
          marginTop: "0.5em",
          paddingTop: "0.5em",
          marginBottom: "0.5em",
        }}
      >
        Drew and Jen
      </h1>
      <h2
        style={{
          marginBottom: 24,
          fontWeight: 75,
          fontSize: "1.2em",
          fontFamily: PARAGRAPH_FONT,
        }}
      >
        July 6th, 2024 &nbsp;&nbsp;|&nbsp;&nbsp; Seattle, WA
      </h2>
      <h2
        style={{
          marginBottom: 32,
          fontSize: "1.2em",
        }}
      >
        <Countdown date={new Date("7/6/2024 5:00 pm")} />
      </h2>
    </Container>
  );
}
