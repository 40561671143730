import { Row, Col } from "react-bootstrap";
import {
  FaWineGlassAlt,
  FaUtensils,
  FaMountain,
  FaPalette,
  FaHiking,
  FaHotel,
} from "react-icons/fa";

import { PageHeader } from "../components/PageHeader";
import { HorizontalLine } from "../components/HorizontalLine";
import { ACCENT_COLOR } from "../constants";
import { useIsMobile } from "../util/UseIsMobile";

interface SectionData {
  title: string;
  link: string;
  desc: string;
}

interface Section {
  header: string;
  items: SectionData[];
  icon: JSX.Element;
}

const HOTEL_DATA: SectionData[] = [
  {
    title: "Hotel Recommendations",
    link: "https://docs.google.com/spreadsheets/d/1BML2cdcpY_3D5Zi95KW3PEHrQtfloaJx24ncmMX13Uw/edit#gid=1984771362",
    desc: "We don’t have experience with Seattle Hotels, but Cindy has built a world-class spreadsheet to help you find accommodations.",
  },
];

const FOOD_DATA: SectionData[] = [
  {
    title: "Espresso Vivace",
    link: "https://espressovivace.com/",
    desc: 'We live on coffee, and the espresso here is second only to Drew’s home setup. They carry the requisite pastries, as well as their home-roasted beans so you can recreate the experience at home!',
  },
  {
    title: "Oddfellows Cafe + Bar",
    link: "https://www.oddfellowscafe.com/",
    desc: "We love this cozy-yet-bustling spot for brunch. From eggs to granola, they nail it all. If you ask nicely during a quiet time, they might seat you in the Secret Garden! After brunch, go next door to Elliot Bay Book Company to peruse Seattle’s largest independent bookstore.",
  },
  {
    title: "Bakery Nouveau",
    link: "https://bakerynouveau.com/",
    desc: "Bakery Nouveau turns its croissants into the most delightful sandwiches and twice-baked pastries in town. If you’re looking for a quick lunch or something to eat on the trail, this is an excellent way to go! We love the California Club.",
  },
  {
    title: "General Porpoise",
    link: "https://gpdoughnuts.com/",
    desc: "Get the vanilla creme donut. Seriously, even if you order something else, make sure to also get the vanilla creme donut. Whether you eat it at the top of Cable Line or at the cafe with a glass of champagne, you will love this decadent treat.",
  },
  {
    title: "MariPili Tapas Bar",
    link: "http://chilissouthindianrestaurant.com/",
    desc: "We like to bring friends and order the entire menu; you really can’t go wrong here.",
  },
  {
    title: "Plum Bistro",
    link: "https://plumbistro.com/",
    desc: "This is an institution. Chef Howell places an emphasis on fresh, locally sourced ingredients and a celebratory atmosphere.",
  },
  {
    title: "Homer",
    link: "https://www.restauranthomer.com/",
    desc: "Our Beacon Hill favorite doesn’t accept reservations, but if you line up right before they open you’ll have no trouble getting in. This is our favorite Mediterranean food in Seattle, and don’t sleep on the soft serve window!",
  },
  {
    title: "Serafina",
    link: "https://serafinaseattle.com/",
    desc: "This old-school Italian restaurant is a delicious place to spend your evening; we almost hosted our reception in the garden!",
  },
];

const DRINK_DATA: SectionData[] = [
  {
    title: "Schilling Cider House",
    link: "https://schillingcider.com/",
    desc: "Share a tasting flight to recreate Drew & Jen’s second date!",
  },
  {
    title: "Bait Shop",
    link: "https://www.baitshopseattle.com/",
    desc: "We used to live right around the corner from here, and firmly believe that nothing beats enjoying a painkiller on a hot day!",
  },
  {
    title: "Zig Zag Cafe",
    link: "https://www.instagram.com/zigzagcafeseattle/",
    desc: "Opened in 1999, Zig Zag is credited with reviving the modern craft cocktail movement. They serve a decadent dinner, but the cocktails are why it’s famous. Nestled around Pike Place Market, it is the perfect spot to end your day of adventuring. Get the Spanish Coffee for a show.",
  },
  {
    title: "Canon",
    link: "https://www.canonseattle.com/",
    desc: "This is our favorite post-dinner bar and just so happens to be across the street from MariPili. They don’t take reservations, so get there early. Inside, you’ll find the world’s largest American Whiskey collection. The zero-proof drinks are fantastic!",
  },
  {
    title: "Needle and Thread",
    link: "https://www.tavernlaw.com/needle-thread",
    desc: "This Capitol Hill speakeasy doesn’t have a menu. To order, tell the bartender what you’re feeling or a fun fact like your favorite season, and they’ll create something bespoke. Make a reservation well in advance.",
  },
];

const CULTURE_DATA: SectionData[] = [
  {
    title: "Chihuly Glass Museum",
    link: "https://www.chihulygardenandglass.com/",
    desc: 'Dale Chihuly’s eponymous museum sits right in the shadow of the Seattle Space Needle, lending itself to a colorful and romantic view of the city. The delicate chandeliers and imposing installations are bursting with color and will leave you in wonder.',
  },
  {
    title: "Seattle Art Museum",
    link: "https://www.seattleartmuseum.org/",
    desc: 'SAM will have West Coast Counterculture and Jacob Lawrence shows in July, on top of the excellent permanent exhibitions. Jen loves strolling through the Modern Art and Native American galleries!',
  },
  {
    title: "The Seattle Aquarium",
    link: "https://www.seattleaquarium.org/",
    desc: "Jen thinks that cuttlefish might just be the coolest animals on the planet. Spend an afternoon here learning about them, interacting with sea anemone, and watching otters hold hands. This is a great place for kids and adults alike.",
  },
  {
    title: "The Seattle Great Wheel",
    link: "https://seattlegreatwheel.com/",
    desc: "Right around the corner from the aquarium, you’ll find our local waterfront ferris wheel. This is super fun, and if you keep a close eye on the water you might spy some sea lions or otters.",
  }
];

const OUTDOORS_DATA: SectionData[] = [
  {
    title: "Pike Place Market",
    link: "https://www.pikeplacemarket.org/",
    desc: "Stroll through the historic building sampling X. Stop by the Chukar Cherries stand for a quintessential Seattle treat. Jen likes to bring these as hostess gifts.",
  },
  {
    title: "Ballard Farmers Market",
    link: "https://www.sfmamarkets.com/visit-ballard-farmers-market",
    desc: "We love a Sunday farmers market, and this is one of Seattle’s best. Explore historic Ballard while strolling through farm stands to find pottery, honey, and other treats. Find the veggie quesadilla for the most delightful hangover-curing meal!",
  },
  {
    title: "The Ballard (Hiram M. Chittenden) Locks",
    link: "https://ballardlocks.org/",
    desc: "The locks connect Lake Union and Lake Washington to Puget Sound, allowing boats to safely navigate the 26ft drop in water level. There is a delightful waterfront park with sculptures and a salmon ladder where you can watch them return to their spawning grounds. July is the high season for Sockeye, and the Chinook will just be starting their migration.",
  },
  {
    title: "Golden Gardens",
    link: "https://www.seattle.gov/parks/allparks/golden-gardens-park",
    desc: "This popular beach is a 5 minute drive from our reception venue. The water’s cold, but on a sunny day you’ll find people hanging out, playing frisbee, and enjoying a book with a stunning view of the Olympic mountains. It’s a great place for photos, so you might see us there on July 6, 2024. ;)",
  },
  {
    title: "Snoqualmie Falls",
    link: "https://www.snoqualmiefalls.com/",
    desc: "If you’re looking for nature without the effort, Snoqualmie Falls is a 276-foot waterfall that has you covered. A sacred site for the Snoqualmie tribe and a source of hydroelectric power for the city, it is a roughly 40 minute drive from Seattle that’s worth it for brunch in the lodge!",
  },
  {
    title: "Troll Hunting",
    link: "https://www.seattleschild.com/five-giant-trolls-coming-to-seattle-this-summer/",
    desc: "The Fremont Troll guards the Aurora bridge, but Danish artist Thomas Dambo recently installed five new wooden trolls across the Seattle region. The easiest ones to get to are in Ballard (near our reception venue) and West Seattle (a short car ride from our welcome picnic area). But if you have some spare time, take a ferry out to Bainbridge or Vashon islands for a delightful adventure!",
  },
];

const HIKING_DATA: SectionData[] = [
  {
    title: "Cable Line",
    link: "https://www.alltrails.com/trail/us/washington/cable-line-trail-to-west-tiger-3",
    desc: "This is Jen’s training hike of choice; 20 minutes from the city with a mean incline, it’ll get your blood pumping. At the top, look out for Mt. Rainier, paragliders, and Downtown Seattle! Pro tip: bring a vanilla creme donut from General Porpoise to enjoy at the top! Drew counters that there is nothing redeeming about this hike.",
  },
  {
    title: "Poo Poo Point",
    link: "https://www.wta.org/go-hiking/hikes/poo-poo-point",
    desc: "Not far from Cable Line, you’ll find the much more moderate Poo Poo point trail. But the views at the top are even better, with Mt. Rainier perfectly framed for your summit selfie.",
  },
  {
    title: "Rattlesnake Ledge",
    link: "https://www.wta.org/go-hiking/hikes/rattlesnake-ledge",
    desc: "This short and sweet hike will take you up to a stunning viewpoint overlooking Rattlesnake lake and the surrounding mountains. However, it gets very busy and parking can be in high demand. If you’re coming from the East Coast, use the time change to your advantage!",
  },
  {
    title: "Mt. Si",
    link: "https://www.wta.org/go-hiking/hikes/mount-si",
    desc: "This classic Seattle hike will take you through dense forests and steep terrain. As you ascend, you’ll find breathtaking vistas and lush valleys for your summit lunch. Just seriously don’t climb the haystack.",
  },
  {
    title: "Mailbox Peak",
    link: "https://www.wta.org/go-hiking/hikes/mailbox-peak",
    desc: "In 1960, Carl Heine installed a mailbox at the top of this mountain and created an icon in Seattle hiking. Local mountaineers use his “old” trail to prepare for climbing volcanoes, but the new, better maintained and more moderate one will save your knees. Jen’s advice is to take the old trail up, and the new trail down. If you can reach the summit in 2 hours, you’re ready for Rainier!",
  },
];

const sections: Section[] = [
  {
    header: "Hotels",
    items: HOTEL_DATA,
    icon: <FaHotel style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Food",
    items: FOOD_DATA,
    icon: <FaUtensils style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Drinks",
    items: DRINK_DATA,
    icon: <FaWineGlassAlt style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Culture",
    items: CULTURE_DATA,
    icon: <FaPalette style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Outdoors",
    items: OUTDOORS_DATA,
    icon: <FaMountain style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Hiking",
    items: HIKING_DATA,
    icon: <FaHiking style={{ width: 24, height: 24 }} />,
  },
];

export function ThingsToDoPage() {
  const isMobile = useIsMobile();
  const iconTopMargin = isMobile ? 0 : 4;
  return (
    <div>
      <PageHeader text={"Things To Do"} />
      <div
        className="justify-content-md-center"
        style={{
          width: "90%",
          margin: "0 auto",
        }}>
        <p>Summer is our favorite time in Seattle, and we’re so excited for our out-of-towners to experience it!</p>

        <p>From brewery hopping in Ballard, to a museum & market crawl through downtown, or wandering the hipster Pike/Pine corridor, there’s a lot to do. Below you’ll find just a fraction of our classic summertime activities.</p>


        <iframe
          title="map of Drew & Jen's favorite things"
          src="https://www.google.com/maps/d/embed?mid=15aWfc_DD9N66-mR-ApdJzhCl2pNTfYI&ehbc=2E312F"
          width="90%"
          height="550"
        ></iframe>
      </div>
      {sections.map((s) => (
        <Row
          key={s.header}
          className="justify-content-md-center"
          style={{ marginTop: 24 }}
        >
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={12}
            style={{ textAlign: "left", display: "flex" }}
          >
            <span style={{ marginTop: iconTopMargin, marginRight: 8 }}>
              {s.icon}
            </span>
            <h3>{s.header}</h3>
          </Col>
          <Col sm={8} xs={12} md={8} lg={7}>
            {s.items.map((item, i) => {
              return (
                <div
                  key={i}
                  style={{ textAlign: "left", marginBottom: 16 }}
                >
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: ACCENT_COLOR }}
                  >
                    {item.title}
                  </a>
                  <p>{item.desc}</p>
                </div>
              );
            })}
          </Col>
          <Col xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
            <HorizontalLine />
          </Col>
        </Row>
      ))}
    </div>
  );
}
