// Colors.

/*
Chris and Constance colors
const CREAM = "#F4EEEA";
const DARK_BLUE_TEXT = "#003153";
const PEACH = "#C69EA1";
const TERRACOTTA = "#B85657";
*/

/*
const OFF_WHITE = "#FFF5E0";
const LIGHT_RED = "#FF6969";
const DARK_RED = "#C70039";
const DARK_BLUE = "#141E46";
*/

const OFF_WHITE = "#F0ECE5";
const LIGHT_GRAY = "#B6BBC4";
const MED_BLUE = "#31304D";
const DARK_BLUE = "#161A30";

export const ACCENT_COLOR = MED_BLUE;
export const SELECTED_NAV_BACKGROUND = LIGHT_GRAY;
export const TEXT_COLOR = DARK_BLUE;
export const BACKGROUND_COLOR = OFF_WHITE;

// Fonts.
export const HEADER_FONT = "Modernline";
export const PARAGRAPH_FONT = "Marcellus";
